<template>
    <promo-landing-page
        image-path-in-assets="images/pages/promo/amazonLogo.svg"
        image-width="161"
        title="Get 10% off at Amazon!"
        message="Next time you shop on Amazon using your Aven Card, get 10% back – up to $50 – as statement&nbsp;credit"
        :cta-text="$t('global.cta.gotIt')"
        :on-cta-click="returnToRoot"
    />
</template>

<script>
    import PromoLandingPage from '@/views/promo/PromoLandingPage'
    import { navigation } from '@/mixins/navigation'

    export default {
        name: 'PromoAmazon50CreditFirstPurchase',
        components: {
            'promo-landing-page': PromoLandingPage,
        },
        mixins: [navigation],
    }
</script>
